import React, { useState, useEffect } from 'react';
import './Program.css';
import axios from 'axios';

function Program() {
  const [userRole] = useState(localStorage.getItem('role'));
  const [students, setStudents] = useState([]);
  const [filterProgram, setFilterProgram] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('alphabetical');
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [showJoinForm, setShowJoinForm] = useState(false);
  const [sessionCount, setSessionCount] = useState('');
  const [programCode, setProgramCode] = useState('');
  const [hoverText, setHoverText] = useState('');
  const [sessionsData, setSessionsData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    if (userRole === 'Admin') {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('Authentication token not found. Please log in again.');
        return;
      }
      fetchAllStudents();
    }
  }, [userRole]);

  useEffect(() => {
    fetchSessionsData();
  }, []);

  const fetchAllStudents = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('Token not found. Please log in again.');
        return;
      }
      const response = await axios.get('/api/studentsWithPrograms', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStudents(response.data);
    } catch (error) {
      console.error('Failed to fetch all students:', error);
    }
  };

  const fetchSessionsData = async () => {
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.get(`/api/sessions/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const data = {};
      response.data.forEach((item) => {
        console.log("new sessions data construction input", item);
        data[item.program_type] = {
          sessions: item.sessions, // Sessions attended
          totalClasses: item.total_classes, // Total classes enrolled for
        };
      });
      setSessionsData(data);
    } catch (error) {
      console.error('Error fetching sessions data:', error);
      setErrorMessage('Failed to fetch sessions data.');
    }
  };

  const updateStudentLevel = async (studentId, programType, newLevel, index, programIndex) => {
    try {
      // Send the updated level to the backend
      await axios.post(
        '/api/update-student-level',
        {
          unique_id: studentId,
          programType,
          newLevel,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );

      // Update the student's level in the state
      setStudents((prevStudents) =>
        prevStudents.map((student, idx) => {
          if (idx === index) {
            const updatedLevels = [...student.levels];
            updatedLevels[programIndex] = newLevel;
            return { ...student, levels: updatedLevels };
          }
          return student;
        })
      );

      alert(`Student level updated to ${newLevel} for ${programType}.`);
    } catch (error) {
      console.error('Failed to update student level:', error);
      alert(`Failed to update student level: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const confirmCancel = () => {
    if (window.confirm('Are you sure you want to cancel your enrollment?')) {
      deleteEnrollment();
    }
  };

  // Filter, Search, and Sort functions
  const filteredStudents = students
    .filter((student) => {
      // Filter by program
      if (filterProgram !== 'All') {
        return student.programs.includes(filterProgram);
      }
      return true;
    })
    .filter((student) => {
      // Search by name
      return student.name.toLowerCase().includes(searchQuery.toLowerCase());
    })
    .sort((a, b) => {
      // Sort based on sortOption
      if (sortOption === 'alphabetical') {
        return a.name.localeCompare(b.name);
      } else if (sortOption === 'age') {
        return a.age - b.age;
      } else if (sortOption === 'numPrograms') {
        return b.programs.length - a.programs.length;
      }
      return 0;
    });

  // Handle filter change
  const handleFilterChange = (e) => {
    setFilterProgram(e.target.value);
  };

  // Handle search query change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle sort option change
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const showJoin = () => {
    setShowJoinForm(true);
  };

  // Non-admin user functions
  const programCodes = {
    acrobatics: 'HDFAR1',
    tumbling: 'HDFCT2',
    fitness: 'HDFPF3',
  };

  const confirmJoin = async () => {
    const unique_id = localStorage.getItem('userId');
    const correctCode = programCodes[selectedProgram.toLowerCase()];
    if (programCode !== correctCode) {
      alert(`Invalid program code. Please enter the correct code for ${selectedProgram}.`);
      return;
    }
    if (!sessionCount) {
      alert('Please select a session option.');
      return;
    }
    const sessions_enrolled = sessionCount === 'Join the Club' ? 12 : parseInt(sessionCount, 10);
    try {
      const response = await axios.post(
        '/api/joinProgram',
        {
          programType: selectedProgram,
          unique_id,
          sessions_enrolled,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );

      alert(response.data.message);
      setSessionsData((prevData) => ({
        ...prevData,
        [selectedProgram]: {
          sessions: 0,
          totalClasses: sessions_enrolled,
        },
      }));
      setShowJoinForm(false);
      closeModal();
      fetchSessionsData(); // Refresh sessions data
    } catch (error) {
      alert(`Failed to join program: ${error.response ? error.response.data.message : error.message}`);
      setErrorMessage('Failed to join program.');
    }
  };

  const deleteEnrollment = async () => {
    const userId = localStorage.getItem('userId');
    try {
      await axios.delete(`/api/enrollment/${userId}`, {
        data: {
          programType: selectedProgram,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      alert('Your enrollment has been canceled.');
      closeModal();
      fetchSessionsData();
    } catch (error) {
      console.error('Error deleting enrollment:', error);
    }
  };

  const showDetails = (program) => {
    setSelectedProgram(program);
  };

  const closeModal = () => {
    setSelectedProgram(null);
    setProgramCode('');
    setShowJoinForm(false);
  };

  
  const getModalContent = (program) => {
    switch (program) {
      case 'acrobatics':
        return {
          title: 'Acrobatics',
          content: (
            <div className="modal-content-flex">
              {/* Beginner Section */}
              <div className="modal-section">
                <h3 className="section-title">Beginner</h3>
                <p className="section-description">
                  This program focuses on the basics of acrobatic skills, emphasizing fundamental
                  techniques and body control suitable for beginners. It's designed to be fun and
                  engaging, using interactive methods with sessions scheduled once or twice weekly to
                  provide consistency and ample opportunity for skill refinement. Safety is of utmost
                  importance in tumbling classes for children. Instructors prioritize proper warm-up
                  and cooldown routines to prevent injuries, ensuring movements are appropriate for
                  each child's developmental stage to minimize the risk of strain to build confidence
                  and creativity.
                </p>
                <ul className="skills-list">
                  <li>Forward Roll</li>
                  <li>Backward Roll</li>
                  <li>Handstand</li>
                  <li>Cartwheel</li>
                  <li>Round-Off</li>
                  <li>Front Walkover</li>
                  <li>Back Walkover</li>
                  <li>Handstand Forward Roll</li>
                  <li>Front Tuck</li>
                  <li>Back Tuck</li>
                </ul>
              </div>

              {/* Advanced Section */}
              <div className="modal-section">
                <h3 className="section-title">Advanced</h3>
                <button className="close-modal-btn" onClick={closeModal}>
                  &times;
                </button>
                <p className="section-description">
                  For those who have mastered the basics, this advanced program offers more challenging
                  acrobatic sequences. A comprehensive tumbling program is designed to teach children a
                  variety of acrobatic skills, focusing on fundamental techniques, body control, and
                  spatial awareness suitable for their age and ability level. I employ interactive and
                  dynamic teaching methods, incorporating games, storytelling, and imaginative
                  activities to make learning tumbling both fun and stimulating, nurturing creativity
                  and confidence in young athletes aiming to refine skills and improve strength,
                  coordination, and flexibility.
                </p>
                <ul className="skills-list">
                  <li>Back Extension Roll</li>
                  <li>Front Handspring</li>
                  <li>Back Handspring</li>
                  <li>Aerial Cartwheel</li>
                  <li>Back Pike</li>
                  <li>Back Layout</li>
                  <li>Whip Back</li>
                  <li>Front Pike</li>
                  <li>Front Layout</li>
                  <li>Back Layout 1/2</li>
                  <li>Back Layout 1/1</li>
                </ul>
              </div>
            </div>
          ),
          price: '$120 per session',
        };
      case 'tumbling':
        return {
          title: 'Tumbling',
          content: (
            <div className="modal-content-flex">
              {/* Beginner Section */}
              <div className="modal-section">
                <h3 className="section-title">Beginner</h3>
                <p className="section-description">
                  This beginner tumbling program teaches basic skills, focusing on flexibility and
                  foundational movements in a fun and safe environment. Classes for the tumbling program
                  are usually held once or twice a week to maintain consistency over the course of
                  several weeks. The length of each session, which ranges from 45 minutes to an hour,
                  provides plenty of opportunity for skill development and practice. In children's
                  tumbling lessons, safety comes first. Instructors place a strong emphasis on
                  age-appropriate progressions that progressively increase coordination and strength.
                  They also ensure that the atmosphere is safe and clear of risks.
                </p>
                <div className="skills-grid">
                  <div className="skills-category">
                    <h5>TURNS</h5>
                    <ul>
                      <li>Half turn on one foot</li>
                      <li>Full turn on one foot</li>
                      <li>Full turn with free leg in scale above horizontal</li>
                      <li>Full turn illusion turn</li>
                      <li>Full turn with free leg at a 180° split position</li>
                      <li>Double turn (720° turn)</li>
                    </ul>
                  </div>
                  <div className="skills-category">
                    <h5>JUMPS</h5>
                    <ul>
                      <li>Straight Jump</li>
                      <li>Tuck Jump</li>
                      <li>Straddle Jump</li>
                      <li>Split Jump</li>
                      <li>Pike Jump</li>
                      <li>Wolf Jump</li>
                      <li>Straight Jump 1/1 turn</li>
                      <li>Tuck Jump 1/1 turn</li>
                      <li>Wolf Jump 1/2 turn</li>
                      <li>Straddle Jump 1/2 turn</li>
                      <li>Split Jump 1/2 turn</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Advanced Section */}
              <div className="modal-section">
                <h3 className="section-title">Advanced</h3>
                <button className="close-modal-btn" onClick={closeModal}>
                  &times;
                </button>
                <p className="section-description">
                  The advanced tumbling program introduces more complex sequences and techniques, helping
                  students develop their coordination, strength, and tumbling skills to a higher level.
                  Children can acquire a variety of tumbling skills through a Comprehensive Learning
                  program that includes age- and skill-appropriate tumbling sequences, flexibility
                  exercises, and basic movements. I use fun and interactive techniques, such as games,
                  obstacle courses, and themed exercises, to help young athletes gain confidence and
                  enjoy learning to tumble.
                </p>
                <div className="skills-grid">
                  <div className="skills-category">
                    <h5>LEAPS</h5>
                    <ul>
                      <li>Cat Leap</li>
                      <li>Hitch Kick</li>
                      <li>Sissone</li>
                      <li>Tour jeté</li>
                      <li>Split Leap</li>
                      <li>Side Leap</li>
                      <li>Switch Leg Leap</li>
                      <li>Ring Leap</li>
                    </ul>
                  </div>
                  <div className="skills-category">
                    <h5>BASIC TUMBLING PASSES</h5>
                    <ul>
                      <li>Round-off- Back Handspring</li>
                      <li>Round-off- Back Handspring- Back Tuck</li>
                      <li>Front Handspring– Front Tuck</li>
                      <li>Front Tuck- Round-off- Back Handspring</li>
                      <li>Round-off- Back Handspring- Back Layout</li>
                    </ul>
                  </div>
                </div>
              </div>
              
            </div>
          ),
          price: '$120 per session',
        };
      case 'fitness':
        return {
          title: 'Personal Fitness Trainer',
          content: (
            <div className="modal-content-flex">
              {/* Beginner Section */}
              <div className="modal-section">
                <h3 className="section-title">Beginner</h3>
                <p className="section-description">
                  This fitness program introduces basic exercises and routines tailored to beginners.
                  This program spans several weeks, typically with sessions held multiple times per week
                  for consistency and optimal results. Each session lasts around 60 minutes, allowing
                  ample time for warm-up, workout, cool-down, and stretching exercises. Participants are
                  encouraged to track their progress and make adjustments to their routines as needed to
                  achieve their desired fitness outcomes, focusing on building a strong foundation for
                  long-term health and wellness.
                </p>
                <p className="training-note">Training based on trial session</p>
              </div>

              {/* Advanced Section */}
              <div className="modal-section">
                <h3 className="section-title">Advanced</h3>
                <button className="close-modal-btn" onClick={closeModal}>
                  &times;
                </button>
                <p className="section-description">
                  For those looking to push their limits, the advanced fitness program offers
                  personalized, intensive workouts designed to achieve specific fitness goals. A
                  comprehensive Personal Fitness program is designed to help individuals improve their
                  physical health and wellness through tailored exercise routines and healthy lifestyle
                  practices. I employ a variety of interactive and engaging methods, including
                  personalized workout plans, goal setting, and nutritional guidance, to make fitness
                  enjoyable and sustainable for participants. Each session focuses on fundamental
                  movements, strength training, cardiovascular exercises, and flexibility routines,
                  tailored to individual fitness levels and goals.
                </p>
                <p className="training-note">Training based on trial session</p>
              </div>
            </div>
          ),
          price: '$125 per session',
        };
      default:
        return { title: '', content: null, price: '' };
    }
  };

  const modalContent = selectedProgram ? getModalContent(selectedProgram) : {};

  return (
    <div>
      <div className="full-width-image">
        <img
          src={`${process.env.PUBLIC_URL}/image/top.jpg`}
          className="card1-img-top"
          alt="top"
        />
        <p className="booking-note">
    You can have a view of the various programs available and which caters to your skill and preferences. After completing  your trial session and decided with which program(s) you would like to enroll for, click Join. 
  </p>
      </div>

      {userRole === 'Admin' ? (
        // Admin View
        <div className="admin-section">
          <h2>Student Management</h2>
          <div className="filters">
            <div className="filter-group">
              <label>Filter by Program:</label>
              <select value={filterProgram} onChange={handleFilterChange}>
                <option value="All">All</option>
                <option value="acrobatics">Acrobatics/Acro</option>
                <option value="tumbling">Cheer Tumbling</option>
                <option value="fitness">Personal Fitness</option>
              </select>
            </div>
            <div className="filter-group">
              <label>Search by Name:</label>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Enter student name"
              />
            </div>
            <div className="filter-group">
              <label>Sort by:</label>
              <select value={sortOption} onChange={handleSortChange}>
                <option value="alphabetical">Alphabetical</option>
                <option value="age">Age</option>
                <option value="numPrograms">Number of Programs</option>
              </select>
            </div>
          </div>

          <div className="students-table">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  {filterProgram === 'All' ? (
                    <>
                      <th>Age</th>
                      <th>Gender</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Address</th>
                      <th>Instagram</th>
                    </>
                  ) : (
                    <>
                      <th>Sessions Attended</th>
                      <th>Enrolled Sessions</th>
                      <th>Level</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredStudents.length > 0 ? (
                  filteredStudents.map((student, index) => {
                    if (filterProgram === 'All') {
                      // Display general information
                      return (
                        <tr key={student.unique_id} className={`table-row row-${index}`}>
                          <td>{student.name}</td>
                          <td>{student.age}</td>
                          <td>{student.gender}</td>
                          <td>{student.mail}</td>
                          <td>{student.phone_no}</td>
                          <td>{student.address}</td>
                          <td>{student.insta}</td>
                        </tr>
                      );
                    } else {
                      // Display program-specific information
                      const programIndex = student.programs.findIndex(
                        (prog) => prog === filterProgram
                      );
                      if (programIndex === -1) {
                        // Student is not enrolled in the selected program
                        return null;
                      }
                      return (
                        <tr key={student.unique_id} className={`table-row row-${index}`}>
                          <td>{student.name}</td>
                          <td>{student.sessions[programIndex]}</td>
                          <td>{student.totalClasses[programIndex]}</td>
                          <td>
                            <div className="level-toggle">
                              <span>{student.levels[programIndex]}</span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={student.levels[programIndex] === 'Advanced'}
                                  onChange={(e) =>
                                    updateStudentLevel(
                                      student.unique_id,
                                      filterProgram,
                                      e.target.checked ? 'Advanced' : 'Beginner',
                                      index,
                                      programIndex
                                    )
                                  }
                                />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })
                ) : (
                  <tr>
                    <td colSpan={filterProgram === 'All' ? '6' : '4'}>No students found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        // Non-Admin View
        <>
          <section id="programs" className="container py-5">
            <div className="card1" onClick={() => showDetails('acrobatics')}>
              <img
                src={`${process.env.PUBLIC_URL}/image/acrobatics.jpg`}
                className="card1-img-top"
                alt="Acrobatics"
              />
              <div className="card-body">
                <h2 className="card-title">Acrobatics/Acro</h2>
              </div>
            </div>
            <div className="card1" onClick={() => showDetails('tumbling')}>
              <img
                src={`${process.env.PUBLIC_URL}/image/tumbling.jpg`}
                className="card1-img-top"
                alt="Tumbling"
              />
              <div className="card1-body">
                <h2 className="card1-title">Cheer Tumbling</h2>
              </div>
            </div>
            <div className="card1" onClick={() => showDetails('fitness')}>
              <img
                src={`${process.env.PUBLIC_URL}/image/fitness.JPG`}
                className="card1-img-top"
                alt="Personal Fitness"
              />
              <div className="card1-body">
                <h2 className="card1-title">Personal Fitness</h2>
              </div>
            </div>
          </section>

          <div className="session-options-container">
          
            <div className="session-button-container">
              <button
                className="session-btn"
                onMouseEnter={() =>
                  setHoverText(
                    'Enroll for 5 Sessions to get an idea on what to learn and have a feel of the atmosphere. See if you got what it takes to take it to the next level. Students are enrolled as a beginner and will be moved to advanced based on their performance, which is decided by the coach. Charges apply per class.'
                  )
                }
                onMouseLeave={() => setHoverText('')}
              >
                5 Sessions
              </button>
              <button
                className="session-btn"
                onMouseEnter={() =>
                  setHoverText(
                    'Enroll for 10 Sessions to see how it fits into your weekly schedule and if you find it comfortable to take two classes a week. Students are enrolled as a beginner and will be moved to advanced based on their performance, which is decided by the coach. Charges apply per class.'
                  )
                }
                onMouseLeave={() => setHoverText('')}
              >
                10 Sessions
              </button>
              <button
                className="session-btn"
                onMouseEnter={() =>
                  setHoverText(
                    'Join the Tumble Club for exclusive access to events, clinics, and merchandise. For further details book an appointment with Coach Hank.'
                  )
                }
                onMouseLeave={() => setHoverText('')}
              >
                JOIN THE CLUB
              </button>
            </div>
            {hoverText && <div className="session-hover-text">{hoverText}</div>}
          </div>
          <p className="booking-note">
          Above are the three packages that are availabale for everyone looking to enroll for a program. Carefully view them to get a good idea on which package works best for you and select it in the booking form.
          </p>

          {selectedProgram && (
        <div className="modal-overlay">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{modalContent.title}</h5>
                <button className="close-modal-btn" onClick={closeModal}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {modalContent.content}
                <div className="price-tag">
                  <span className="price-text">{modalContent.price}</span>
                </div>
              </div>
              <div className="modal-footer centered-buttons">
                {sessionsData[selectedProgram] ? (
                  sessionsData[selectedProgram].sessions >=
                  sessionsData[selectedProgram].totalClasses ? (
                    <>
                      <p>Your subscription for {selectedProgram} has ended.</p>
                      <button className="renew-btn" onClick={showJoin}>
                        Renew
                      </button>
                      <button className="cancel-btn" onClick={confirmCancel}>
                        Cancel Enrollment
                      </button>
                    </>
                  ) : (
                    <p className="booking-note">
             You have Enrolled for this program. Book your sessions based on your availabality in the Book Sessions page. View your classes in your Profile page </p>)
                ) : (
                  <button className="join-btn" onClick={showJoin}>
                    JOIN
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showJoinForm && (
  <div className="program-modal-overlay">
    <div className="program-modal-dialog">
      <div className="program-modal-content">
        <div className="program-modal-header">
          <h5 className="program-modal-title">Join {selectedProgram}</h5>
          <button className="close-modal-btn" onClick={closeModal}>
            &times;
          </button>
        </div>
        <div className="program-session-options">
            <div className="session-option">
              <label className="session-container">
                <input
                  type="radio"
                  name="sessionCount"
                  value="5"
                  checked={sessionCount === '5'}
                  onChange={(e) => setSessionCount(e.target.value)}
                />
                <span>5 Sessions</span>
                <svg viewBox="0 0 64 64" height="2em" width="2em">
                  <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" className="path"></path>
                </svg>
              </label>
            </div>
            <div className="session-option">
              <label className="session-container">
                <input
                  type="radio"
                  name="sessionCount"
                  value="10"
                  checked={sessionCount === '10'}
                  onChange={(e) => setSessionCount(e.target.value)}
                />
                <span>10 Sessions</span>
                <svg viewBox="0 0 64 64" height="2em" width="2em">
                  <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" className="path"></path>
                </svg>
              </label>
            </div>
            <div className="session-option">
              <label className="session-container">
                <input
                  type="radio"
                  name="sessionCount"
                  value="12"
                  checked={sessionCount === '12'}
                  onChange={(e) => setSessionCount(e.target.value)}
                />
                <span>Join the Club</span>
                <svg viewBox="0 0 64 64" height="2em" width="2em">
                  <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" className="path"></path>
                </svg>
              </label>
              <p>
                *Warning: Do not choose the 'Join the Club' option without consulting or running it by Coach Hank. If done so, your account will be deactivated until further notice.*
              </p>
            </div>
          </div>
          
        <div className="program-modal-body">
          <p>
            All students are automatically set to beginner and based on their performance and
            motivation can be upgraded to the advanced class by the Coach.
          </p>
          <div className="terms-and-conditions-scrollbox">
            <h3>1. <strong>Membership Fees & Payment Methods</strong></h3>
            <ul>
              <li>All membership fees are due in full at the time of registration, unless otherwise stated in a specific payment plan.</li>
              <li>We accept the following payment methods:
                <ul>
                  <li>Credit/Debit Cards (Visa, Mastercard, American Express)</li>
                  <li>PayPal</li>
                  <li>Bank Transfers</li>
                  <li>ApplePay, Venmo, Cash</li>
                </ul>
              </li>
              <li>Payment plans may be available for certain membership tiers (e.g., Gold, Silver). Please inquire with management for details.</li>
            </ul>
            <h3>2. <strong>Payment Schedule</strong></h3>
            <ul>
              <li>Payments for monthly memberships are due on the 1st of each month.</li>
              <li>If joining after the 1st of the month, a prorated amount may be charged for that month.</li>
              <li>For quarterly or annual memberships, full payment must be made upfront unless otherwise arranged in writing.</li>
              <li>Private lessons, workshops, and merchandise purchases must be paid in advance to confirm your booking.</li>
            </ul>
            <h3>3. <strong>Late Payments & Penalties</strong></h3>
            <ul>
              <li>Payments not received within 7 days of the due date will incur a late fee of $25.</li>
              <li>If payment is not received within 14 days, the member's account will be suspended, and they will not be permitted to participate in any club activities until the account is brought current.</li>
              <li>Failure to resolve outstanding payments within 30 days may result in the termination of membership and loss of any reserved spots in upcoming classes or events.</li>
            </ul>
            <h3>4. <strong>Cancellation Policy for Private Lessons & Classes</strong></h3>
            <ul>
              <li><strong>Cancellations</strong> made more than 24 hours in advance of a scheduled private lesson or class will receive a full refund or credit towards future sessions.</li>
              <li><strong>Cancellations</strong> made less than 24 hours in advance will be charged the full amount, except in cases of verified medical emergencies.</li>
              <li>TumbleClubHD reserves the right to cancel or reschedule lessons due to instructor availability or unforeseen circumstances. In these cases, members will receive a full refund or the option to reschedule.</li>
            </ul>
            <h3>5. <strong>Membership Cancellation & Refund Policy</strong></h3>
            <ul>
              <li><strong>Monthly Memberships</strong>: Cancellations must be submitted in writing at least 15 days before the start of the next billing cycle. No refunds will be provided for cancellations after the billing date.</li>
              <li><strong>Quarterly or Annual Memberships</strong>: No refunds will be issued for early cancellation unless due to a medical condition verified by a doctor. In such cases, a prorated refund may be issued based on the remaining membership period.</li>
              <li>Membership cancellations must be requested in writing via email to hank.dean27@gmail.com.</li>
            </ul>
            <h3>6. <strong>No-Refund Policy for Merchandise</strong></h3>
            <ul>
              <li>All merchandise sales are final. No refunds or exchanges will be provided unless there is a defect with the item, in which case a replacement will be issued.</li>
              <li>Members are responsible for ensuring correct sizes and item choices when placing orders.</li>
            </ul>
            <h3>7. <strong>Payment Failure</strong></h3>
            <ul>
              <li>If a member’s payment method is declined or a transaction fails, they will be notified immediately and given 5 business days to provide an alternative payment method.</li>
              <li>Continued failure to make payments may result in suspension or termination of membership.</li>
            </ul>
            <h3>8. <strong>Discounts and Promotions</strong></h3>
            <ul>
              <li>Any discounts, promotions, or referral bonuses must be applied at the time of registration and cannot be retroactively applied.</li>
              <li>TumbleClubHD reserves the right to alter or terminate any discounts or promotions at any time without prior notice.</li>
            </ul>
            <h3>9. <strong>Suspension or Termination of Membership</strong></h3>
            <ul>
              <li>TumbleClubHD reserves the right to suspend or terminate memberships for repeated late payments, violation of club policies, or inappropriate behavior. No refunds will be given in the case of termination for breach of club rules.</li>
            </ul>
            <h3>10. <strong>Refunds for Canceled Events</strong></h3>
            <ul>
              <li>In the event that TumbleClubHD must cancel a workshop, clinic, or other scheduled event, members will receive a full refund or credit towards future events.</li>
            </ul>
            <h3>11. <strong>Payment Disputes</strong></h3>
            <ul>
              <li>All payment disputes must be submitted in writing to hank.dean27@gmail.com within 30 days of the disputed charge.</li>
              <li>TumbleClubHD will investigate the claim and respond within 10 business days. If a refund is approved, it will be issued within 5-7 business days following resolution.</li>
            </ul>
            <h3>12. <strong>Agreement to Payment Policy</strong></h3>
            <p>
              By registering for TumbleClubHD, you agree to abide by this payment policy. Failure to adhere to the terms outlined may result in suspension or termination of membership.
            </p>
          </div>
          <div className="terms-and-conditions-acceptance">
            <label>
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              I have read and accept the Terms and Conditions.
            </label>
          </div>
          <div className="program-code-input">
            <label htmlFor="program-code">
              Enter Program Code for {selectedProgram}:
            </label>
            <input
              type="text"
              id="program-code"
              value={programCode}
              onChange={(e) => setProgramCode(e.target.value)}
              placeholder="Enter Program Code"
            />
          </div>
        </div>
        <div className="program-modal-footer">
          <button
            className="program-confirm-btn"
            onClick={confirmJoin}
            disabled={!termsAccepted}
          >
            Confirm
          </button>
          <button className="program-close-modal-btn" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)}

        </>
      )}
      <div className="full-width-image">
        <img
          src={`${process.env.PUBLIC_URL}/image/bottom.jpg`}
          className="card1-img-top"
          alt="top"
        />
      </div>
    </div>
  );
}

export default Program;

import React from 'react';
import './About.css';

function About() {
  return (
    <section id="about" className='index'>
      <div className="about-container">
        {/* Profile Header */}
        <div className="about-profile-header">
          <div className="about-profile-image">
            <img src={`${process.env.PUBLIC_URL}/image/image1.jpg`} alt="Hank Dean" />
          </div>
          <div className="about-profile-info">
            <h1>Hank Dean</h1>
            <p className="about-lead">Lead Instructor & Choreographer</p>
          </div>
        </div>

        {/* Education & Training */}
        <div className="about-education-section about-section">
          <h2>Education & Training</h2>
          <p>Alvin Academy of Dance, Red Bank, NJ - Classical Ballet, Jazz, Tap, & Aero</p>
          <p>Steps Dance Studio, New York, NY - Jazz, Ballet, Hip Hop, & Adagio</p>
          <p>Alts Gymnastics School, Eatontown, NJ - Power tumbling & Trampoline</p>
          <p>Alley Dance Conservatory, New York - Modern Dance & Jazz</p>
        </div>

        <div className="about-images-section">
          <img src={`${process.env.PUBLIC_URL}/image/image2.jpg`} alt="hank2" />
        </div>

        {/* Certifications */}
        <div className="about-certifications-section about-section">
          <h2>Certifications</h2>
          <ul>
            <li>Aerobics & Fitness Association of America (AFAA)</li>
            <li>International Dance Exercise Association (IDEA)</li>
            <li>CPR Certified</li>
            <li>Cryogenics Certified</li>
            <li>Fully licensed and insured</li>
          </ul>
        </div>

        {/* Images Section */}
        <div className="about-images-section">
        <img src={`${process.env.PUBLIC_URL}/image/image8.jpeg`} alt="hank5" />
        
        <img src={`${process.env.PUBLIC_URL}/image/logo2.jpg`} alt="Logo" />
          <img src={`${process.env.PUBLIC_URL}/image/image4.jpeg`} alt="hank4" />
          
        </div>

        {/* Awards & Recognitions */}
        <div className="about-awards-section about-section">
          <h2>Awards & Recognitions</h2>
          <ul>
            <li>Reebok National Aerobics Champion</li>
            <li>Mixed Pairs Competition: 1st—1995, 1996; 2nd—1997</li>
            <li>Mens Singles: 2nd—1995, 1996; 3rd—1997</li>
          </ul>
        </div>

        <div className="about-images-section">
          <img src={`${process.env.PUBLIC_URL}/image/image3.jpg`} alt="hank3" />
          </div>

        {/* Professional Experience */}
        <div className="about-experience-section about-section">
          <h2>Professional Experience</h2>
          <p>Sovereign Coaching Institute: Founder - Flexibility, Strength, Plyometrics training, Tumbling coach.</p>
          <p>Manager of Team Loop Kicks - MMA Flexibility Coaching.</p>
          <p>On Lok Lifeways - Senior Citizen Mobility and Strength Training.</p>
        </div>

        {/* Images Section */}
        <div className="about-images-section">
          <img src={`${process.env.PUBLIC_URL}/image/image6.jpeg`} alt="hank6" />
          <img src={`${process.env.PUBLIC_URL}/image/image7.jpeg`} alt="hank7" />
        </div>
      </div>
    </section>
  );
}

export default About;

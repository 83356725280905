import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [name, setName] = useState('');  
  const [role, setRole] = useState('');  // Added role state
  const navigate = useNavigate();

  useEffect(() => {
    const storedName = localStorage.getItem('name');
    const token = localStorage.getItem('token');
    const storedRole = localStorage.getItem('role'); // Retrieve role from localStorage
    if (storedName && token) {
      setIsLoggedIn(true);
      setName(storedName);
      setRole(storedRole); // Set role in state
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
    // Refresh the page after logout to reset state
    window.location.reload();
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <img src={`${process.env.PUBLIC_URL}/image/logo.jpg`} alt="Logo" />
      </div>
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link" to="/">HOME</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={"/program"}>
            {role === 'Admin' ? 'STUDENTS' : 'PROGRAMS'}
          </Link>
        </li>
        {isLoggedIn && (
          <li className="nav-item">
            <Link className="nav-link" to="/scheduler">
              {role === 'Admin' ? 'CHECK BOOKINGS' : 'BOOK SESSIONS'}
            </Link>
          </li>
        )}
        <li className="nav-item">
          <Link className="nav-link" to="/about">ABOUT</Link>
        </li>
        {name ? (
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Hello, {name}
            </a>
            <div className="dropdown-content">
              <Link className="dropdown-item" to="/profile">View Profile</Link>
              <a className="dropdown-item" href="#" onClick={handleLogout}>Logout</a>
            </div>
          </li>
        ) : (
          <li className="nav-item">
            <Link className="nav-link loginBtn" to="/login">LOGIN/SIGNUP</Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;


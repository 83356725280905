import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';

axios.defaults.baseURL = 'http://18.191.171.183:5000'; 

function Login() {
  const [isSignup, setIsSignup] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [insta, setInstagram] = useState('');
  const [address, setAddress] = useState('');
  const [phone_no, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  // Email validation function
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    // Validate email during signup
    if (isSignup && !validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    const endpoint = isSignup ? '/api/signup' : '/api/login';
    let payload;

    if (isSignup) {
      payload = { 
        email, 
        password, 
        name, 
        age: parseInt(age), 
        gender, 
        insta,
        address,  
        phone_no,
      };
    } else {
      payload = { email, password };
    }

    try {
      console.log('Sending request to:', endpoint);
      console.log('Payload:', payload);
      const response = await axios.post(endpoint, payload);
      console.log('Response:', response.data);

      if (isSignup) {
        // Signup was successful
        setSuccessMessage('Signup successful! Please log in.');
        // Reset form fields
        setEmail('');
        setPassword('');
        setName('');
        setAge('');
        setGender('');
        setInstagram('');
        setAddress('');
        setPhoneNumber('');
        setEmailError('');
        // Switch back to login mode
        setIsSignup(false);
      } else {
        const { token, userId, name, role } = response.data;
        
        // Store the token and userId in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
        localStorage.setItem('name', name); 
        localStorage.setItem('role', role);

        if (token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        // Redirect to home page
        navigate('/');
        window.location.reload();
      }
    } catch (error) {
      console.error('Error details:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        setError(error.response.data.message || 'An error occurred. Please try again.');
      } else if (error.request) {
        console.error('No response received:', error.request);
        setError('No response from server. Please try again later.');
      } else {
        console.error('Error message:', error.message);
        setError('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <section className='index'>
      <div className="login-container">
        <h1>{isSignup ? 'Sign Up' : 'Login'}</h1>
        <form onSubmit={handleSubmit}>
          {error && <p className="error">{error}</p>}
          {successMessage && <p className="success">{successMessage}</p>}
          <input 
            type="text" 
            placeholder="Email" 
            value={email} 
            onChange={(e) => {
              setEmail(e.target.value);
              if (isSignup) {
                if (!validateEmail(e.target.value)) {
                  setEmailError('Please enter a valid email address.');
                } else {
                  setEmailError('');
                }
              }
            }} 
            required
          />
          {emailError && <p className="error">{emailError}</p>}
          <input 
            type="password" 
            placeholder="Password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required
          />
          {isSignup && ( 
            <>
              <input 
                type="text" 
                placeholder="Name" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                required
              />
              <input 
                type="number" 
                placeholder="Age" 
                value={age} 
                onChange={(e) => setAge(e.target.value)} 
                required
              />
              <input 
                type="text" 
                placeholder="Gender" 
                value={gender} 
                onChange={(e) => setGender(e.target.value)} 
                required
              />
              <input 
                type="text" 
                placeholder="Address" 
                value={address} 
                onChange={(e) => setAddress(e.target.value)} 
                required
              />
              <input 
                type="text" 
                placeholder="Phone Number" 
                value={phone_no} 
                onChange={(e) => setPhoneNumber(e.target.value)} 
                required
              />
              <input 
                type="text" 
                placeholder="Instagram (Optional)" 
                value={insta} 
                onChange={(e) => setInstagram(e.target.value)} 
              />
            </>
          )}
          <button type="submit" disabled={isSignup && emailError}>{isSignup ? 'Sign Up' : 'Login'}</button>
          <p onClick={() => {
            setIsSignup(!isSignup);
            setError('');
            setSuccessMessage('');
            setEmailError('');
          }}>
            {isSignup ? 'Already have an account? Login' : "Don't have an account? Sign up"}
          </p>
        </form>
      </div>
    </section>
  );
}

export default Login;

// Scheduler.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Scheduler.css';

function Scheduler() {
  const [currentDayIndex, setCurrentDayIndex] = useState(new Date().getDay());
  const [bookedTimeSlots, setBookedTimeSlots] = useState({});
  const [scheduledClasses, setScheduledClasses] = useState([]);
  const [userRole] = useState(localStorage.getItem('role'));
  const [enrolledPrograms, setEnrolledPrograms] = useState([]);
  const [unavailableSlots, setUnavailableSlots] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [sessionsData, setSessionsData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isBookingDisabled, setIsBookingDisabled] = useState(false);
  const limit = 15; // Number of classes per page

  useEffect(() => {
    fetchScheduledClasses(currentPage);
    fetchEnrolledPrograms();
    fetchUnavailableSlots();
    fetchSessionsData();
  }, [currentPage]);

  useEffect(() => {
    // Check if any of the enrolled programs have no sessions left
    const checkBookingStatus = () => {
      const canBook = enrolledPrograms.some(
        (program) =>
          sessionsData[program] &&
          sessionsData[program].sessions < sessionsData[program].totalClasses
      );

      setIsBookingDisabled(!canBook);
    };

    checkBookingStatus();
  }, [enrolledPrograms, sessionsData]);

  const fetchEnrolledPrograms = async () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.error('User ID not found in local storage');
      return;
    }
    try {
      const response = await axios.get(`/api/programs/enrolled/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      console.log('Enrolled Programs Response:', response.data);
  
      // Use program_type from the response to set enrolled programs
      const programs = response.data.enrolledPrograms.map((program) => program.program_type);
      setEnrolledPrograms(programs);
    } catch (error) {
      console.error('Error fetching enrolled programs:', error);
    }
  };
  
  
  const fetchSessionsData = async () => {
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.get(`/api/sessions/${userId}`);
      const data = {};
      response.data.forEach((item) => {
        data[item.program_type] = {
          sessions: item.sessions,
          totalClasses: item.total_classes,
        };
      });
      setSessionsData(data);
    } catch (error) {
      console.error('Error fetching sessions data:', error);
    }
  };

  const fetchScheduledClasses = async (page) => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.error('User ID not found in local storage');
      return;
    }
  
    try {
      const response = await axios.get('/api/classes/upcoming', {
        params: { userId, page, limit },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
  
      // Filter out classes that are attended (if not handled by the backend)
      const classes = response.data.classes || [];
      const filteredClasses = classes.filter((cls) => cls.attended !== 1);
  
      setScheduledClasses(filteredClasses);
      setTotalPages(Math.ceil((response.data.total || 0) / limit));
    } catch (error) {
      console.error('Error fetching scheduled classes:', error);
    }
  };  

  const fetchUnavailableSlots = async () => {
    try {
      const response = await axios.get('/api/unavailable-slots');
      const unavailable = response.data.reduce(
        (acc, slot) => {
          const datePart = new Date(slot.Date).toISOString().split('T')[0];
          const timePart = slot.slots.trim();
          // Use '|' as a delimiter
          const key = `${slot.day}|${timePart}|${datePart}`;
          return {
            ...acc,
            [key]: true,
          };
        },
        {}
      );
      setUnavailableSlots(unavailable);

      if (userRole === 'Admin') {
        setBookedTimeSlots(unavailable); // Pre-select unavailable slots for admin
      }
    } catch (error) {
      console.error('Failed to fetch unavailable slots:', error);
    }
  };

  const handleProgramTypeChange = (event) => {
    const program = event.target.value;
    console.log(`Selected program: ${program}`);
  };


  const handleCheckboxChange = (checkboxId) => {
    setBookedTimeSlots((prev) => ({
      ...prev,
      [checkboxId]: !prev[checkboxId],
    }));
  };

  const token = localStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  const getNextDateForDay = (dayIndex) => {
    const today = new Date();
    const todayIndex = today.getDay();
    const daysUntilNext = (dayIndex - todayIndex + 7) % 7 || 7;
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + daysUntilNext);

    // Format date to 'YYYY-MM-DD'
    const year = nextDate.getFullYear();
    const month = String(nextDate.getMonth() + 1).padStart(2, '0');
    const day = String(nextDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const bookClass = async () => {
    const userId = localStorage.getItem('userId');
    const program = document.getElementById('program-type').value;
    // Check if sessionsData and program values are valid
    if (!sessionsData[program]) {
      alert(`Looks like we cannot retrieve your subscription details as you are not enrolled in the Program. Please Join the program begore booking classes.`);
      return;
    }
    // Check if sessions are remaining
    const { sessions, totalClasses } = sessionsData[program];
    if (sessions >= totalClasses) {
      console.log("Sessions are greater than total allowed");
      // Display alert message when the user's subscription has ended
      alert(`Your subscription for ${program} has ended. Please renew to book more classes.`);
      return;
    }
  
    const day = daysOfWeek[currentDayIndex];
    const date = getNextDateForDay(currentDayIndex);
    const checkboxes = document.querySelectorAll(
      "#scheduler-time-slots input[type='checkbox']:checked"
    );
  
    if (checkboxes.length === 0) {
      alert('Please select at least one slot to book.');
      return;
    }
  
    const selectedSlots = Array.from(checkboxes)
      .map((checkbox) => {
        const checkboxId = checkbox.id;
        if (unavailableSlots[checkboxId]) {
          alert(`The slot ${checkbox.value} on ${day} is unavailable.`);
          return null;
        }
        return {
          program_type: program,  // Pass program_type
          unique_id: userId,  // Pass unique_id
          day,
          date,
          slot: checkbox.value,
          status: 'pending',
        };
      })
      .filter(Boolean);
  
    if (selectedSlots.length === 0) {
      return;
    }
  
    try {
      await axios.post('/api/classes', {
        userId,
        newScheduledClasses: selectedSlots,
      });
      fetchScheduledClasses(currentPage);
      alert('Class booked successfully!');
    } catch (error) {
      console.error('Error booking class:', error);
      alert('Failed to book class. Please try again later.');
    }
  };
  
  
  const updateUnavailableSlots = async () => {
    const selectedSlots = Object.keys(bookedTimeSlots).filter(
      (key) => bookedTimeSlots[key]
    );

    const previousUnavailableSlots = Object.keys(unavailableSlots);
    const slotsToMakeAvailable = previousUnavailableSlots.filter(
      (slot) => !selectedSlots.includes(slot)
    );
    const slotsToMakeUnavailable = selectedSlots.filter(
      (slot) => !previousUnavailableSlots.includes(slot)
    );

    try {
      await axios.post('/api/update-slots', {
        slotsToMakeAvailable,
        slotsToMakeUnavailable,
      });
      fetchUnavailableSlots(); // Refresh the unavailable slots
      alert('Slots have been updated.');
    } catch (error) {
      console.error('Error updating slots:', error);
    }
  };

  const updateClassStatus = async (id, status, unique_id, programType) => {
    if (userRole !== 'Admin') {
      alert('Unauthorized: Only admins can update class status.');
      return;
    }
    try {
      await axios.post(
        `/api/classes/${id}/${unique_id}`,
        { status, programType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Fetch sessions data to update the state
      fetchSessionsData();
      fetchScheduledClasses(currentPage);
    } catch (error) {
      console.error('Error updating class status:', error);
    }
  };
  

  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const timeSlots = [
    '8:00 AM - 9:00 AM',
    '9:00 AM - 10:00 AM',
    '10:00 AM - 11:00 AM',
    '11:00 AM - 12:00 PM',
    '1:00 PM - 2:00 PM',
    '2:00 PM - 3:00 PM',
    '3:00 PM - 4:00 PM',
    '4:00 PM - 5:00 PM',
    '5:00 PM - 6:00 PM',
    '6:00 PM - 7:00 PM',
  ];

  return (
    <section className='index'>
    <div className="scheduler-container">
      <div className="scheduler-booking-container">
        <button
          className="scheduler-toggle-button"
          onClick={() => setShowForm(!showForm)}
        >
          {userRole === 'Admin' ? 'Manage Class Availability' : 'Book A Session'}
        </button>
        {showForm && (
        <div className="scheduler-booking-form">
          <label htmlFor="program-type">Program Type:</label>
          <select id="program-type" onChange={handleProgramTypeChange}>
           {enrolledPrograms.length > 0 ? (
             enrolledPrograms.map((program, index) => (
              <option key={index} value={program}>
                 {program.charAt(0).toUpperCase() + program.slice(1)}
               </option>
              ))
           ) : (
            <option disabled>No Programs Available</option>
              )}
          </select>
            <div className="scheduler-day-selector">
              <button
                onClick={() =>
                  setCurrentDayIndex(
                    (prev) => (prev - 1 + daysOfWeek.length) % daysOfWeek.length
                  )
                }
              >
                Prev
              </button>
              <div>
                <h3>{daysOfWeek[currentDayIndex]}</h3>
                <span>{getNextDateForDay(currentDayIndex)}</span>
              </div>
              <button
                onClick={() =>
                  setCurrentDayIndex((prev) => (prev + 1) % daysOfWeek.length)
                }
              >
                Next
              </button>
            </div>
            <label>Available Time Slots:</label>
            <div id="scheduler-time-slots">
              {timeSlots.map((slot, index) => {
                const timePart = slot.split(' -')[0].trim();
                const checkboxDate = getNextDateForDay(currentDayIndex);
                const checkboxId = `${daysOfWeek[currentDayIndex]}|${timePart}|${checkboxDate}`;

                const isUnavailable = unavailableSlots[checkboxId];
                const isDisabled = userRole !== 'Admin' && isUnavailable;
                const slotClass = isUnavailable ? 'unavailable-slot' : '';

                return (
                  <div key={index} className="scheduler-time-slot-row">
                    <input
                      type="checkbox"
                      value={slot}
                      id={checkboxId}
                      checked={bookedTimeSlots[checkboxId] || false}
                      onChange={() => handleCheckboxChange(checkboxId)}
                      disabled={isDisabled}
                    />
                    <label htmlFor={checkboxId} className={slotClass}>
                      {slot}
                    </label>
                  </div>
                );
              })}
            </div>
            <button
              onClick={userRole === 'Admin' ? updateUnavailableSlots : bookClass}
              //disabled={isBookingDisabled} // Disable button if no sessions are left
            >
              {userRole === 'Admin' ? 'Update Availability' : 'Book Class'}
            </button>
          </div>
        )}
      </div>
      <div className="scheduler-schedule-container">
        <h2>Scheduled Sessions</h2>
         {/* Status Legend */}
         <div className="status-legend">
  <span className="status-item">
    <span className="status-square pending"></span>
    Status - Pending
  </span>
  <span className="status-item">
    <span className="status-square approved"></span>
    Status - Approved
  </span> 
  <span className="status-item">
    <span className="status-square declined"></span>
    Status - Declined
  </span>
</div>

  {/* Formal Note */}
  <p className="booking-note">
    * <strong>Please Note:</strong> After booking a session, it will be reviewed by our team. Once your booking is attended to, Your Scheduled Session status will change accordingly. If your session has been Approved please go your Profile to view your Upcoming Sessions in you Profile page.
  </p>
        <div id="scheduler-scheduled-classes">
          {scheduledClasses.length > 0 ? (
            scheduledClasses.map((cls, index) => (
              <div key={index} className={`scheduler-schedule-card ${cls.status}`}>
                <p>Name: {cls.name}</p>
                <p>Program: {cls.program_type}</p>
                <p>Day: {cls.day}</p>
                <p>Date: {new Date(cls.class_date).toLocaleDateString('en-US')}</p>
                <p>Time: {cls.time_slot}</p>
                {userRole === 'Admin' && (
                  <div className="scheduler-admin-buttons">
                  <button
  onClick={() =>
    updateClassStatus(
      cls.booking_id,
      'booked',
      cls.unique_id,
      cls.program_type
    )
  }
>
  ✔ Approve
</button>
<button
  onClick={() =>
    updateClassStatus(
      cls.booking_id,
      'not-booked',
      cls.unique_id,
      cls.program_type
    )
  }
>
  ✘ Decline
</button>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No scheduled classes to display</p>
          )}
        </div>
        {/* Pagination controls */}
        <div className="pagination">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
    </section>
  );
}

export default Scheduler;
